// Dependencies
import React from "react"
import PropTypes from "prop-types"

function Footer({ title }) {
  return <footer>{`© ${new Date().getFullYear()} ${title}`}</footer>
}

Footer.propTypes = {
  title: PropTypes.string,
}

export default Footer
