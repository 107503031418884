// Dependencies
import React from "react"

function Header() {
  return (
    <header className="header">
      <div className="logo-container">
        <img src={'/logo.svg'} alt="VREC" />
      </div>
    </header>
  )
}

export default Header
